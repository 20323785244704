import "../scss/site.scss";

const navigationBackground = function () {
  let options = {
    rootMargin: "-80px 0px 0px 0px",
  };

  let observer = new IntersectionObserver((e) => {
    if (e[0].isIntersecting) {
      showHideNavBackground(false);
    } else {
      showHideNavBackground(true);
    }
  }, options);

  observer.observe(document.querySelector("#top"));

  const showHideNavBackground = function (shouldShow) {
    let header = document.querySelector("header");

    return shouldShow
      ? header.classList.add("bg--show")
      : header.classList.remove("bg--show");
  };
};

let isMobileNavOpen = false;

navigationBackground();

// loads headshot after initial paint
const loadHeadshot = function () {
  let image = document.createElement("img");
  image.src = "./assets/headshot.png";
  image.alt = "Pono Chong";

  let image2 = document.createElement("img");
  image2.src = "./assets/headshot.png";
  image2.alt = "Pono Chong";
  image2.id = "headshot";

  let mobileContainer = document.createElement("div");
  mobileContainer.classList.add("mobile-image-container");

  mobileContainer.appendChild(image);

  let h2 = document.querySelector("#about-h2");
  let about = document.querySelector(".about__content");

  about.insertBefore(mobileContainer, h2);

  about.insertBefore(image2, mobileContainer);
};

loadHeadshot();

// changes bottom property of about image to always align with bottom of the about section
window.addEventListener("resize", windowResizeEventHandler);

function windowResizeEventHandler() {
  changeBottomPropertyOfAboutImage();
  closeMobileMenuOnResize();
}

function changeBottomPropertyOfAboutImage() {
  let aboutHeight = about.offsetHeight;
  let viewport = window.innerHeight;

  if (aboutHeight > viewport) {
    let headshot = document.getElementById("headshot");
    headshot.setAttribute(
      "style",
      `height: ${viewport - aboutHeight - viewport * 0.05}px`
    );
  } else {
    headshot.removeAttribute("style");
  }
}

function closeMobileMenuOnResize() {
  if (!isMobileNavOpen) return;

  let { innerWidth } = window;

  if (innerWidth > 768) {
    closeMenu();
  }
}

// changing label colors of contact form logic
let inputs = document.querySelectorAll(".contact-input");

inputs.forEach((input) => {
  if (input.parentNode.id !== "phone-inputs") {
    input.addEventListener("focus", () => {
      let label = input.previousElementSibling;
      label.setAttribute("style", "color: #4095a0");
    });

    input.addEventListener("blur", () => {
      let label = input.previousElementSibling;
      label.removeAttribute("style");
    });
  } else {
    let phoneInputs = input.parentNode;
    let label = phoneInputs.previousElementSibling;
    let siblingInputs = phoneInputs.getElementsByTagName("input");

    input.addEventListener("focus", () => {
      phoneInputs.setAttribute("style", "color: #4095a0");
      label.setAttribute("style", "color: #4095a0");

      for (let i = 0; i < siblingInputs.length; i++) {
        siblingInputs[i].setAttribute(
          "style",
          "border-bottom: 3px solid #4095a0"
        );
      }
    });

    input.addEventListener("blur", () => {
      phoneInputs.removeAttribute("style");
      label.removeAttribute("style");

      for (let i = 0; i < siblingInputs.length; i++) {
        siblingInputs[i].removeAttribute("style");
      }
    });
  }
});

// contact form submit logic
const onSubmit = (data) => {
  fetch("/api/email", {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((results) => {
      if (results.status !== 200) {
        throw new Error();
      }
      alert("Thank you, your message has been sent.");

      let form = document.getElementById("contact-form");

      form.reset();
    })
    .catch((err) => {
      console.log(err);
      alert("Sorry, something went wrong.  Please try submitting that again.");
    });
};

let contactForm = document.getElementById("contact-form");

contactForm.addEventListener("submit", (e) => {
  e.preventDefault();

  let name = document.getElementById("name").value;
  let org = document.getElementById("org").value;
  let email = document.getElementById("email").value;
  let phone =
    document.getElementById("area").value +
    document.getElementById("prefix").value +
    document.getElementById("suffix").value;
  let message = document.getElementById("message").value;

  let formData = { name, org, email, phone, message };

  return onSubmit(formData);
});

// navButton toggle logic
let navButton = document.querySelector(".navButton");
let exitButton = document.querySelector(".exitButton");

navButton.addEventListener("click", () => {
  return openMenu();
});

exitButton.addEventListener("click", () => {
  return closeMenu();
});

function openMenu() {
  let menu = document.querySelector(".header-right");
  const body = document.querySelector("body");

  menu.setAttribute("style", "right: 0; opacity: 1;");
  body.classList.add("scroll-lock");
  isMobileNavOpen = true;
}

function closeMenu() {
  let menu = document.querySelector(".header-right");
  const body = document.querySelector("body");

  body.classList.remove("scroll-lock");
  menu.removeAttribute("style");
  isMobileNavOpen = false;
}

// scrollTo logic for menu buttons
let links = document.querySelectorAll(".link");

const linkClickHandler = function (e) {
  e.preventDefault();
  // get current y-coordinate
  let currY = window.scrollY;

  // get target y-coordinate
  let href = e.currentTarget.getAttribute("href");
  let targetEl = document.querySelector(href);
  let { top } = targetEl.getBoundingClientRect();

  let targetY = top + currY;
  let additionalPixels = 0;

  if (href == "#services") {
    additionalPixels = -50;
  }

  // get menu height
  let menuHeight = document
    .querySelector("header")
    .getBoundingClientRect().height;

  // close menu (if mobile)
  const isMobile = window.innerWidth <= 768;
  if (isMobile) {
    closeMenu();
  }

  // scroll to target from current plus menu height
  window.scrollBy({
    top: targetY - currY - menuHeight + additionalPixels,
    left: 0,
    behavior: "smooth",
  });
};

links.forEach((link) => {
  link.addEventListener("click", linkClickHandler);
});
